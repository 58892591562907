import { getSiteUrl } from './getSiteUrl';
import { getWpUrl } from './getWpUrl';

const trimmedWpUrl = getWpUrl();
const trimmedSiteUrl = getSiteUrl();

/**
 * Replace all occurrences of WordPress URL with Site URL
 * @param string - The string to replace
 * @returns The string with all occurrences of WordPress URL replaced with Site URL
 */

export const replaceWithSiteUrl = (string?: string | null) =>
  string?.replace(new RegExp(trimmedWpUrl, 'g'), trimmedSiteUrl);
