import { FC, createContext, useContext, useEffect } from 'react';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { defaultTheme, MaestroThemeProvider } from '@landr/maestro';
import parse from 'html-react-parser';
import { HeaderProps, Header, Footer } from 'layouts';
import { PostFragmentFragment, VideoMetaTag } from 'types';
import { VideoSeoMetaTags } from './SeoVideoMeta';
import { replaceWithSiteUrl } from 'helpers/replaceWithSiteUrl';

interface SeoHeadProps {
  seoHead?: string | null;
  videoSeoMetaTags?: VideoMetaTag[];
}

export const SeoHead: FC<SeoHeadProps> = ({ seoHead, videoSeoMetaTags }) => {
  // Yoast will return the site url as the WP admin url, so we need to replace it
  const seoHeadWithSiteUrl = replaceWithSiteUrl(seoHead);

  const fullHead = seoHeadWithSiteUrl ? parse(seoHeadWithSiteUrl) : null;

  if (!fullHead) {
    return null;
  }

  return (
    <NextHead>
      {/* // add favicon as seo header doesn't contain it */}
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/temp-favicon.png"
      />
      {videoSeoMetaTags && <VideoSeoMetaTags metaTags={videoSeoMetaTags} />}
      {fullHead}
    </NextHead>
  );
};

const ReadyContent: FC<{ headerColor: PageWrapperProps['headerColor'] }> = ({
  children,
  headerColor = '#8445D4',
}) => {
  const { isReady } = useRouter();

  useEffect(() => {
    if (!isReady) {
      return;
    }
  }, [isReady]);

  return (
    <>
      <Header color={headerColor} />
      {children}
    </>
  );
};

interface TranslationsContextType {
  translations?: PostFragmentFragment['translations'];
}

const TranslationsContext = createContext<TranslationsContextType>({
  translations: undefined,
});

const TranslationsProvider = TranslationsContext.Provider;

export const useTranslationsContext = () => {
  const context = useContext(TranslationsContext);
  return context;
};

interface PageWrapperProps {
  headerColor?: HeaderProps['color'];
  translations?: PostFragmentFragment['translations'];
}

export const PageWrapper: FC<PageWrapperProps> = ({
  headerColor,
  translations,
  children,
}) => {
  return (
    <TranslationsProvider value={{ translations }}>
      <MaestroThemeProvider theme={defaultTheme}>
        <ReadyContent headerColor={headerColor}>{children}</ReadyContent>
        <Footer />
      </MaestroThemeProvider>
    </TranslationsProvider>
  );
};
