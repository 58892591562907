import {
  ChangeEvent,
  FC,
  FormEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
// Constants
import { SEARCH_KEY } from 'constants/queryParams';
import { SEARCH } from 'constants/routes';
import { useGetGlobalSettings } from 'hooks';
// Icons
import SearchLoupe from '../../../public/icons/search-loupe.svg';

import styles from './SearchForm.module.scss';
import { Button, List, PopoverBase, styled } from '@landr/maestro';

const StyledSpinner = styled(Button)`
  height: 0px;
`;

const useSearchFromLocalStorage = () => {
  const LOCAL_STORAGE_SEARCH_HISTORY_KEY = 'post_search_history';

  const getValuesFromLS = () => {
    const itemsFromLS = localStorage.getItem(LOCAL_STORAGE_SEARCH_HISTORY_KEY);

    if (itemsFromLS !== null) {
      return JSON.parse(itemsFromLS) as Array<string>;
    } else {
      return [];
    }
  };

  const addNewItemToLS = (newValue: string) => {
    const itemsFromLS = localStorage.getItem(LOCAL_STORAGE_SEARCH_HISTORY_KEY);

    if (itemsFromLS !== null) {
      const values = JSON.parse(itemsFromLS) as Array<string>;

      const foundElement = values.find((element) => element === newValue);

      if (!foundElement) {
        values.push(newValue);

        localStorage.setItem(
          LOCAL_STORAGE_SEARCH_HISTORY_KEY,
          JSON.stringify(values)
        );
      }
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_SEARCH_HISTORY_KEY,
        JSON.stringify([newValue])
      );
    }
  };

  return {
    getValuesFromLS,
    addNewItemToLS,
  };
};

export const SearchForm: FC<{
  handleMenuToggle: (event: any) => void;
  searchInputRef: RefObject<HTMLInputElement>;
}> = ({ handleMenuToggle, searchInputRef }) => {
  const { getValuesFromLS, addNewItemToLS } = useSearchFromLocalStorage();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const triggerRef = useRef<HTMLFormElement>(null);

  const settings = useGetGlobalSettings();
  const { push, query, pathname } = useRouter();

  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [searchValuesFromLS, setSearchValuesFromLS] = useState<Array<string>>(
    []
  );

  const searchPageIsOpen = pathname === SEARCH;

  useEffect(() => {
    if (searchPageIsOpen) {
      const queryParamDontMatch =
        query &&
        query[SEARCH_KEY] &&
        (searchText === '' || query[SEARCH_KEY] !== searchText);

      if (queryParamDontMatch) {
        setSearchText(query[SEARCH_KEY] as string);
      }
    }

    // NOTE: Do not include "searchText" in the dependencies
    // This effect should be invoked only at the beginning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searchPageIsOpen]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const lsValues = getValuesFromLS();

    if (!isPopoverOpen && lsValues.length) {
      setSearchValuesFromLS(lsValues);
      setIsPopoverOpen(true);
    }

    setSearchText(e.target.value);
  };

  const handleFormSubmit = (e: FormEvent<HTMLButtonElement>) => {
    addNewItemToLS(searchText.trim());

    e.preventDefault();
    e.stopPropagation();

    setIsPopoverOpen(false);

    // Close menu if search values are identical
    if (query[SEARCH_KEY] === searchText) {
      handleMenuToggle(e);
    } else {
      !searchPageIsOpen && setIsLoading(true);
      clearAllBodyScrollLocks();
      push(`${SEARCH}?${SEARCH_KEY}=${searchText}`);
    }
  };

  const searchBar = settings?.searchBar || 'What would you like to learn?';

  return (
    <>
      <form className={styles.root} autoComplete="off" ref={triggerRef}>
        <SearchLoupe
          className={styles.icon}
          onClick={handleFormSubmit}
          aria-hidden="true"
          role="presentation"
          focusable="false"
        />
        <input
          className={styles.input}
          type="search"
          name="search"
          placeholder={searchBar}
          onChange={handleInputChange}
          disabled={isLoading}
          value={searchText}
          ref={searchInputRef}
          required
        />
        {isLoading && (
          <StyledSpinner
            isLoading
            variant="subtle"
            kind="minimal"
            style={{ height: 'none' }}
          />
        )}
        <button
          className={styles.button}
          type="submit"
          // If we use "handleFormSubmit" in form onSubmit -> it will be called twice
          onClick={handleFormSubmit}
        />
      </form>
      {searchValuesFromLS.filter((e) => e.includes(searchText)).length ? (
        <PopoverBase
          anchorRef={triggerRef}
          isOpen={isPopoverOpen}
          onClickOutside={() => setIsPopoverOpen(false)}
        >
          <List sx={{ minWidth: triggerRef.current?.clientWidth }}>
            {searchValuesFromLS
              .filter((e) => e.includes(searchText))
              .slice(0, 5)
              .map((value) => {
                return (
                  <List.Item
                    key={value}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSearchText(value);
                      setIsPopoverOpen(false);
                    }}
                  >
                    {value}
                  </List.Item>
                );
              })}
          </List>
        </PopoverBase>
      ) : null}
    </>
  );
};
