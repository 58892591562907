import { FC } from 'react';
import { Link } from '@landr/maestro';

import styles from './ProductLink.module.scss';

export interface ProductLinkProps {
  accentColor?: string | null;
  title?: string | null;
  link?: string | null;
}

export const ProductLink: FC<ProductLinkProps> = ({
  accentColor,
  title,
  link,
}) => (
  <Link
    className={styles.link}
    // #D7D6D2 = $grey
    sx={{
      color: '#D7D6D2',
      textDecoration: 'none',
      '&:hover': { textDecoration: 'underline' },
    }}
    size="sm"
    href={link || ''}
  >
    {title}
  </Link>
);
