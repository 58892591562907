import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { Box, Button, MenuInvertedOutlined } from '@landr/maestro';
import { useBreakpoints, useGetGlobalSettings } from 'hooks';

import LandrLarge from '../../../public/icons/landr-large.svg';
import SearchLoupe from '../../../public/icons/search-loupe.svg';
import { useLayoutContext } from '../../../components/LayoutContext/LayoutContext';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { SearchForm } from '../SearchForm';
import { Logo } from '../Logo';
import { CloseButton } from '../CloseButton';
import styles from './Menu.module.scss';

export interface MenuProps {
  isPageLoaded?: boolean;
}

export const Menu: FC<MenuProps> = ({ isPageLoaded }) => {
  const { asPath } = useRouter();
  const { headerMenuItems } = useLayoutContext();

  const [isMenuVisible, setMenuVisibility] = useState<boolean>(false);
  const menuContentRef = useRef<HTMLDivElement>(null);

  const settings = useGetGlobalSettings();

  const { isBreakpointM } = useBreakpoints();

  const headerMenuItemsNodes = headerMenuItems?.nodes || [];

  const searchInputRef = useRef<HTMLInputElement>(null);

  useClickAway(
    menuContentRef,
    () => {
      if (isMenuVisible) {
        setMenuVisibility(false);
        enableBodyScroll(document.body);
      }
    },
    ['click']
  );

  useEffect(() => {
    setMenuVisibility(false);
    clearAllBodyScrollLocks();
  }, [asPath]);

  const handleMenuToggle = (event: MouseEvent) => {
    // otherwise "useClickAway" will be called
    event?.preventDefault();
    event?.stopPropagation();

    if (isMenuVisible) {
      setMenuVisibility(false);
      enableBodyScroll(document.body);
    } else {
      setMenuVisibility(true);
      disableBodyScroll(document.body);
      searchInputRef.current?.focus();
    }
  };

  const navigationTitle =
    settings?.navigationSlideHeadline ||
    'Learnings from the frontlines of music creation';
  const navigationSignOff =
    settings?.navigationSlideSignOff || 'Proudly brought to you by Landr';

  return (
    <>
      {isBreakpointM ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Logo />
          <Button
            size="sm"
            isRounded
            isDropdown
            variant="ghost"
            onClick={handleMenuToggle}
            title="Search"
            sx={{
              height: '24px',
              padding: '4px 8px',
              fontSize: '13px',
              lineHeight: '18px',
              marginRight: '4px',
            }}
          >
            {/* // TODO: Add/retrieve field and translate https://mixgenius.atlassian.net/browse/CORE-11797 */}
            <span>Topics</span>
          </Button>
          <LanguageSwitcher />
          <Button
            size="sm"
            isRounded
            variant="ghost"
            onClick={handleMenuToggle}
            sx={{ marginLeft: '8px', height: '24px' }}
          >
            <SearchLoupe className={styles.searchIcon} />
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <button className={styles.menuButton} onClick={handleMenuToggle}>
              <MenuInvertedOutlined />
            </button>
            <Logo hasLogo={false} />
            <Button
              size="sm"
              isRounded
              variant="ghost"
              onClick={handleMenuToggle}
              sx={{ height: '24px' }}
            >
              <SearchLoupe className={styles.searchIcon} />
            </Button>
          </Box>
        </Box>
      )}
      <div
        className={cn(styles.menu, {
          [styles.open]: isMenuVisible,
          [styles.loaded]: isPageLoaded,
        })}
      >
        <CloseButton onClick={handleMenuToggle} />
        <div ref={menuContentRef}>
          <p className={styles.title}>{navigationTitle}</p>
          <div className={styles.language}>
            <LanguageSwitcher />
          </div>
          <SearchForm
            searchInputRef={searchInputRef}
            handleMenuToggle={handleMenuToggle}
          />
          <nav className={styles.menuList}>
            <ul>
              {headerMenuItemsNodes.map((item) => {
                if (item?.id && item.path && item.label) {
                  const isActive =
                    asPath !== '/'
                      ? item.path.startsWith(asPath)
                      : item.path === asPath;
                  return (
                    <li
                      className={cn(styles.menuItem, {
                        [styles.active]: isActive,
                      })}
                      key={item.id}
                    >
                      <Link href={item.path}>{item.label}</Link>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </nav>
          <div className={styles.copyrightWrapper}>
            <LandrLarge className={styles.landrLogo} />
            <p className={styles.copyrightText}>{navigationSignOff}</p>
          </div>
        </div>
      </div>
    </>
  );
};
