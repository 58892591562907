import { trim } from 'lodash';

/**
 * Get site URL from environment variable
 * @returns {string} siteUrl with protocol and without trailing slash
 */

export function getSiteUrl(): string {
  let siteUrlVar = process.env.NEXT_PUBLIC_VERCEL_URL;

  if (!siteUrlVar) {
    throw new Error('NEXT_PUBLIC_VERCEL_URL is not set in .env file');
  }

  if (siteUrlVar.includes('localhost') && !siteUrlVar.includes('http://')) {
    siteUrlVar = `http://${siteUrlVar}`;
  } else if (!siteUrlVar.includes('https://')) {
    siteUrlVar = `https://${siteUrlVar}`;
  }

  const siteUrl = trim(siteUrlVar, '/');

  return siteUrl;
}
