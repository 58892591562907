import { FC, MouseEvent } from 'react';

import Close from '../../../public/icons/close.svg';
import styles from './CloseButton.module.scss';

interface CloseButtonProps {
  onClick: (event: MouseEvent) => void;
}

export const CloseButton: FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <button
      className={styles.closeButton}
      onClick={onClick}
      type="button"
      title="close menu"
    >
      <Close className={styles.closeIcon} />
    </button>
  );
};
