import React from 'react';
import { radius, themeMode, variant } from '@landr/maestro';
import styled from 'styled-components';
import { productIcons } from './icons';

type ProductVariant =
  | 'mastering'
  | 'samples'
  | 'plugins'
  | 'collaboration'
  | 'extras'
  | 'courses'
  | 'distribution'
  | 'neutral';

export interface ProductIconProps {
  size?: 'sm' | 'md';
  product?: ProductVariant;
}

const StyledProductIcon = styled('div')<ProductIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radius('lg')};
  ${variant({
    prop: 'size',
    variants: {
      sm: {
        width: 28,
        minWidth: 28,
        height: 28,
        fontSize: '16px',
      },
      md: {
        width: 36,
        minWidth: 36,
        height: 36,
        fontSize: '20px',
      },
    },
  })};
  ${variant({
    prop: 'product',
    variants: {
      plugins: {
        color: themeMode({
          light: '#FD9200',
          dark: '#FFE3B3',
        }),
        background: themeMode({
          light: '#FD920052',
          dark: '#FD920052',
        }),
      },
      samples: {
        color: themeMode({
          light: '#FD9200',
          dark: '#FFE3B3',
        }),
        background: themeMode({
          light: '#FD920052',
          dark: '#FD920052',
        }),
      },
      mastering: {
        color: themeMode({
          light: '#A467F1',
          dark: '#F0B6FF',
        }),
        background: themeMode({
          light: '#A467F152',
          dark: '#A467F152',
        }),
      },
      distribution: {
        color: themeMode({
          light: '#1DB691',
          dark: '#74FFB4',
        }),
        background: themeMode({
          light: '#1DB69152',
          dark: '#1DB69152',
        }),
      },
      collaboration: {
        color: themeMode({
          light: '#EA2684',
          dark: '#FFABDD',
        }),
        background: themeMode({
          light: '#EA268452',
          dark: '#EA268452',
        }),
      },
      courses: {
        color: themeMode({
          light: '#3374BF',
          dark: '#B8D2F2',
        }),
        background: themeMode({
          light: '#3374BF52',
          dark: '#3374BF52',
        }),
      },
    },
  })};
`;

export const ProductIcon = (props: ProductIconProps) => {
  const { product = 'neutral', size = 'md', ...rest } = props;
  const icon = productIcons[product];

  return (
    <StyledProductIcon product={product} size={size} {...rest}>
      {icon}
    </StyledProductIcon>
  );
};
