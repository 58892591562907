import { FC, useRef, useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Button } from '@landr/maestro';

import LandrLarge from '../../../public/icons/landr-large.svg';
import { useGetGlobalSettings } from 'hooks';
import { CloseButton } from '../CloseButton';
import { LoginButton } from '../LoginButton';
import { ProductIcon } from '../ProductIcon';
import styles from './ProductMenu.module.scss';

export interface ProductMenuProps {
  isPageLoaded?: boolean;
}

export const ProductMenu: FC<ProductMenuProps> = ({ isPageLoaded }) => {
  const [isMenuVisible, setMenuVisibility] = useState<boolean>(false);
  const menuContentRef = useRef<HTMLDivElement>(null);
  const settings = useGetGlobalSettings();

  const fallbackLink = 'https://www.landr.com';

  const handleMenuToggle = () => {
    if (isMenuVisible) {
      setMenuVisibility(false);
      enableBodyScroll(document.body);
    } else {
      setMenuVisibility(true);
      disableBodyScroll(document.body);
    }
  };

  return (
    <>
      <Button
        size="sm"
        isRounded
        variant="secondary"
        title="Search"
        sx={{
          height: '24px',
          padding: '4px 8px',
          fontSize: '13px',
          lineHeight: '18px',
        }}
        onClick={handleMenuToggle}
      >
        {/* // TODO: Add/retrieve field  https://mixgenius.atlassian.net/browse/CORE-11797 */}
        About LANDR
      </Button>
      <div
        className={cn(styles.menu, {
          [styles.open]: isMenuVisible,
          [styles.loaded]: isPageLoaded,
        })}
      >
        <CloseButton onClick={handleMenuToggle} />
        <div className={styles.menuContent} ref={menuContentRef}>
          <LandrLarge className={styles.landrLogo} />
          <p className={styles.title}>
            {/* // TODO: Add/retrieve field and translate https://mixgenius.atlassian.net/browse/CORE-11797 */}
            Create, master and release your music in one place.
          </p>
          <nav>
            <ul>
              <li className={styles.menuItem}>
                <ProductIcon size="md" product="plugins" />
                <Link href={settings?.headerLinks?.pluginsLink || fallbackLink}>
                  {settings?.headerLinks?.pluginsTitle || ''}
                </Link>
              </li>
              <li className={styles.menuItem}>
                <ProductIcon size="md" product="samples" />
                <Link href={settings?.headerLinks?.samplesLink || fallbackLink}>
                  {settings?.headerLinks?.samplesTitle || ''}
                </Link>
              </li>
              <li className={styles.menuItem}>
                <ProductIcon size="md" product="mastering" />
                <Link
                  href={settings?.headerLinks?.masteringLink || fallbackLink}
                >
                  {settings?.headerLinks?.masteringTitle || ''}
                </Link>
              </li>
              <li className={styles.menuItem}>
                <ProductIcon size="md" product="distribution" />
                <Link
                  href={settings?.headerLinks?.distributionLink || fallbackLink}
                >
                  {settings?.headerLinks?.distributionTitle || ''}
                </Link>
              </li>
              <li className={styles.menuItem}>
                <ProductIcon size="md" product="collaboration" />
                <Link
                  href={
                    settings?.headerLinks?.collaborationLink || fallbackLink
                  }
                >
                  {settings?.headerLinks?.collaborationTitle || ''}
                </Link>
              </li>
              {/* // TODO: Add/retrieve field for link to Courses https://mixgenius.atlassian.net/browse/CORE-11797 */}
              <li className={styles.menuItem}>
                <LoginButton size="lg" />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
