import { FC } from 'react';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { getHost } from '../../utils/host';

interface HeadProps {
  title: string;
  description: string;
  image?: string;
}

export const Head: FC<HeadProps> = ({ title, description, image }) => {
  const { asPath } = useRouter();

  const url = `${process.env.NEXT_PUBLIC_BASE_PATH}${asPath}`;

  return (
    <NextHead>
      {/* <!-- Primary Meta Tags --> */}
      <title>{title}</title>
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/temp-favicon.png"
      />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <link rel="canonical" href={getHost()} />
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {image && <meta property="twitter:image" content={image} />}
    </NextHead>
  );
};
