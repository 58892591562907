import { FC, useEffect, useState } from 'react';
import { Box } from '@landr/maestro';
import cn from 'classnames';

import { useBreakpoints, useGetGlobalSettings } from 'hooks';
import { ProductMenu } from '../ProductMenu';
import { ProductLink } from '../ProductLink';
import { LoginButton } from '../LoginButton';
import { Menu } from '../Menu';
import styles from './NavBar.module.scss';

export interface NavBarProps {
  accentColor?: string | null;
}

export const NavBar: FC<NavBarProps> = ({ accentColor = '#D7D6D2' }) => {
  const [isPageLoaded, setPageLoaded] = useState<boolean>(false);
  const { isBreakpointML } = useBreakpoints();

  const settings = useGetGlobalSettings();

  const sharedLinkProps = { accentColor };

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  }, []);

  return (
    <Box className={cn(styles.root)}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Menu isPageLoaded={isPageLoaded} />
      </Box>
      {isBreakpointML && (
        <div className={styles.desktopLinks}>
          <ProductLink
            title={settings?.headerLinks?.pluginsTitle}
            link={settings?.headerLinks?.pluginsLink}
            {...sharedLinkProps}
          />
          <ProductLink
            title={settings?.headerLinks?.samplesTitle}
            link={settings?.headerLinks?.samplesLink}
            {...sharedLinkProps}
          />
          <ProductLink
            title={settings?.headerLinks?.masteringTitle}
            link={settings?.headerLinks?.masteringLink}
            {...sharedLinkProps}
          />
          <ProductLink
            title={settings?.headerLinks?.distributionTitle}
            link={settings?.headerLinks?.distributionLink}
            {...sharedLinkProps}
          />
          <ProductLink
            title={settings?.headerLinks?.collaborationTitle}
            link={settings?.headerLinks?.collaborationLink}
            {...sharedLinkProps}
          />
          <LoginButton />
        </div>
      )}
      {!isBreakpointML && <ProductMenu isPageLoaded={isPageLoaded} />}
    </Box>
  );
};
