import { FC, useState } from 'react';
import cn from 'classnames';
import { MenuItemFragment } from 'types';

import Arrow from '../../../public/icons/menu-arrow.svg';

import styles from './WidgetBlock.module.scss';

interface WidgetBlockProps {
  title: string;
  menuLinks?: Array<Pick<
    MenuItemFragment,
    'id' | 'path' | 'target' | 'label'
  > | null>;
}

export const WidgetBlock: FC<WidgetBlockProps> = ({ title, menuLinks }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleTitleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div
      className={cn(styles.root, {
        [styles.open]: open,
      })}
    >
      <span className={styles.title} onClick={handleTitleClick}>
        {title}{' '}
        <Arrow
          className={cn(styles.arrowIcon, {
            [styles.open]: open,
          })}
        />
      </span>
      <ul className={styles.menu}>
        {menuLinks?.map((item) => {
          return (
            <li key={item?.id} className={styles.link}>
              <a href={item?.path || ''} target={item?.target || undefined}>
                {item?.label}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
