import {
  Button,
  DropdownMenu,
  Heading,
  Modal,
  useDisclosure,
  Paragraph,
  ButtonGroup,
  Box,
} from '@landr/maestro';
import styles from './LanguageSwitcher.module.scss';
import { useRouter } from 'next/router';
import { useTranslationsContext } from 'layouts/PageWrapper';
import { Locale, WPLocale, getHost, getLocale } from 'utils';
import { useState } from 'react';
import cn from 'classnames';
import Link from 'next/link';

const getNativeLocaleName = (locale?: Locale) => {
  switch (locale) {
    case 'en':
      return 'EN';
    case 'fr':
      return 'FR';
    case 'de':
      return 'DE';
    case 'ja':
      return '日本語';
    case 'es':
      return 'ES';
    case 'pt-br':
      return 'PT';
    case 'it':
      return 'IT';
    default:
      return 'EN';
  }
};

const getFullLocaleName = (locale?: Locale) => {
  switch (locale) {
    case 'en':
      return 'English';
    case 'fr':
      return 'Français';
    case 'de':
      return 'Deutsch';
    case 'ja':
      return '日本語';
    case 'es':
      return 'Español';
    case 'pt-br':
      return 'Português';
    case 'it':
      return 'Italiano';
    default:
      return 'English';
  }
};

const getTranslatedSlug = (translations: any, locale: string) => {
  if (!translations) return undefined;

  const translation = translations?.find(
    (translation: any) => getLocale(translation?.locale) === locale
  );

  const getSanitizedURL = () => {
    try {
      const url = new URL(translation?.href).pathname;
      return url.startsWith('/') ? url.substring(1) : url;
    } catch {
      return translation?.href.startsWith('/')
        ? translation?.href.substring(1)
        : translation?.href;
    }
  };

  const siteHostUrl = getHost();
  const sanitizedHref = getSanitizedURL();

  if (typeof sanitizedHref !== 'string') return undefined;

  return siteHostUrl + sanitizedHref;
};

const getHomeSlug = (locale: string) => {
  const siteHostUrl = getHost();

  if (!locale) return siteHostUrl;

  return siteHostUrl + locale;
};

type ModalContent = {
  [K in Locale]: {
    heading: string;
    description: string;
    cancel: string;
    continue: string;
  };
};

const getModalContent = (locale: Locale) => {
  const content: ModalContent = {
    en: {
      heading: 'Article not available in english',
      description: 'What would you like to do?',
      cancel: 'Stay on the article',
      continue: 'Continue to english',
    },
    fr: {
      heading: 'Article non disponible en français',
      description: 'Que voulez-vous faire?',
      cancel: `Revenir à l'article`,
      continue: 'Continuer en français',
    },
    de: {
      heading: 'Artikel nicht verfügbar auf Deutsch',
      description: 'Was möchten Sie tun?',
      cancel: 'Auf dem Artikel bleiben',
      continue: 'Weiter auf Deutsch',
    },
    ja: {
      heading: '記事は日本語で利用できません',
      description: '何をしたいですか？',
      cancel: '記事に留まる',
      continue: '日本語に進む',
    },
    es: {
      heading: 'Artículo no disponible en español',
      description: '¿Qué te gustaría hacer?',
      cancel: 'Permanecer en el artículo',
      continue: 'Continuar en español',
    },
    'pt-br': {
      heading: 'Artigo não disponível em português',
      description: 'O que você gostaria de fazer?',
      cancel: 'Permanecer no artigo',
      continue: 'Continuar em português',
    },
    it: {
      heading: 'Articolo non disponibile in italiano',
      description: 'Cosa vorresti fare?',
      cancel: `Rimanere sull'articolo`,
      continue: 'Continuare in italiano',
    },
  };

  return content[locale] || content.en;
};

const getPageType = (pathname: string) => {
  if (!pathname)
    return {
      isAuthor: false,
      isCategory: false,
      isPage: false,
      isPost: false,
    };

  const isAuthor = pathname.includes('[author]');
  const isCategory = pathname.includes('[category]');
  const isPage = pathname.includes('[page]');
  const isPost = pathname.includes('[post]');

  return {
    isAuthor,
    isCategory,
    isPage,
    isPost,
  };
};

export const LanguageSwitcher = () => {
  const { locale: activeLocale, locales, push, pathname, asPath } = useRouter();

  const languages = locales?.filter((locale) => locale !== activeLocale);

  const { translations } = useTranslationsContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [targetLocale, setTargetLocale] = useState<Locale>('en');

  const content = getModalContent(targetLocale);

  const openModal = (locale: Locale) => {
    setTargetLocale(locale);
    onOpen();
  };

  const { isAuthor } = getPageType(pathname);

  return (
    <>
      <DropdownMenu placement="bottom-start">
        <DropdownMenu.Trigger>
          <Button
            size="sm"
            isRounded
            isDropdown
            variant="ghost"
            title="Search"
            sx={{
              height: '24px',
              padding: '4px 8px',
              lineHeight: '18px',
              fontSize: '13px',
            }}
          >
            {getNativeLocaleName(activeLocale as Locale)}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.List>
          {(languages as Locale[])?.map((language) => {
            const href = getTranslatedSlug(translations, language);
            const nativeName = getFullLocaleName(language);

            if (isAuthor) {
              // Author pages are not translated and the href is the same for all languages
              return (
                <DropdownMenu.Item
                  key={language}
                  as={Link}
                  href={`${getHost()}${language}${asPath}`}
                  onClick={(event) => {
                    event.preventDefault();
                    return push(asPath, href, { locale: language });
                  }}
                >
                  {nativeName}
                </DropdownMenu.Item>
              );
            }

            return !!href ? (
              <DropdownMenu.Item
                key={language}
                as={Link}
                href={href}
                onClick={(event) => {
                  event.preventDefault();
                  return push(href, href, { locale: language });
                }}
              >
                {nativeName}
              </DropdownMenu.Item>
            ) : (
              <DropdownMenu.Item
                key={language}
                onClick={() => openModal(language)}
              >
                {nativeName}
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.List>
      </DropdownMenu>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <Modal.Content className={styles.modalContent}>
          <Box position="absolute" top="xs" right="xl">
            <Modal.CloseButton />
          </Box>
          <Modal.Section>
            <Box padding="lg">
              <Heading className={styles.heading}>{content.heading}</Heading>
              <Paragraph className={styles.description}>
                {content.description}
              </Paragraph>
              <ButtonGroup isStacked>
                <Button
                  isFull
                  isRounded
                  onClick={onClose}
                  variant="ghost"
                  className={cn(styles.button, styles.cancel)}
                >
                  {content.cancel}
                </Button>
                <Button
                  as="a"
                  isFull
                  isRounded
                  variant="secondary"
                  href={getHomeSlug(targetLocale)}
                  onClick={(event) => {
                    event.preventDefault();
                    return push(getHomeSlug(targetLocale), undefined, {
                      locale: targetLocale,
                    });
                  }}
                  className={cn(styles.button, styles.continue)}
                >
                  {content.continue}
                </Button>
              </ButtonGroup>
            </Box>
          </Modal.Section>
        </Modal.Content>
      </Modal>
    </>
  );
};
