import React from 'react';

export const productIcons = {
  mastering: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="1em"
      height="1em"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M6.709 16.257V3.137M3.61 16.257V13.75M16.01 16.257V9.16M9.809 16.257V6.944M12.91 16.257v-4.893"
      />
    </svg>
  ),
  samples: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="1em"
      height="1em"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.667 9.911h-.833c-1.8 0-1.8-3.289-2.5-3.289s-1.896 10.045-2.917 10.045c-1.02 0-1.883-13.334-2.916-13.334S6.667 10.09 5 10.09H3.334"
      />
    </svg>
  ),
  plugins: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="1em"
      height="1em"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M13.334 5.833h3.333"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M10 4.167h3.334V7.5h-3.333a1.667 1.667 0 1 1 0-3.333Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M7.5 15.833h6.666a2.5 2.5 0 0 0 0-5h-7.5a2.5 2.5 0 1 1 0-5"
      />
    </svg>
  ),
  collaboration: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="1em"
      height="1em"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.166 5.88a.884.884 0 0 0-.877-.88H3.376a.884.884 0 0 0-.877.88v6.62c0 .486.398.88.88.88h3.01v2.5l3.11-2.5h3.783a.887.887 0 0 0 .884-.88V5.88Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m14.166 7.5 3.333-.833v5l-3.333-.834V7.5Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.791 7.692h3.125"
      />
    </svg>
  ),
  extras: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="1em"
      height="1em"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeWidth={1.5}
        d="M4.166 9.167h12.083v6.875c0 .575-.466 1.041-1.041 1.041h-10a1.042 1.042 0 0 1-1.042-1.041V9.167Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.084 6.25H3.334v2.917h13.75V6.25Z"
      />
      <path stroke="currentColor" strokeWidth={1.5} d="M10.209 17.292V3.958" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M12.084 6.25a1.875 1.875 0 1 0-1.875-1.875M10.209 4.375A1.875 1.875 0 1 0 8.334 6.25"
      />
    </svg>
  ),
  courses: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="1em"
      height="1em"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeWidth={1.5}
        d="M15.833 3.125H4.167c-.576 0-1.042.466-1.042 1.042v11.666c0 .576.466 1.042 1.042 1.042h11.666c.576 0 1.042-.466 1.042-1.042V4.167c0-.576-.466-1.042-1.042-1.042Z"
      />
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m8.541 12.292 3.75-2.287-3.75-2.297v4.584Z"
        clipRule="evenodd"
      />
    </svg>
  ),
  distribution: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="1em"
      height="1em"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeWidth={1.5}
        d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m14.584 12.083 2.5 2.5-2.5 2.5"
      />
    </svg>
  ),
  neutral: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      width="1em"
      height="1em"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m15.417 16.25-2.5-1.667-2.916 1.667-2.917-1.667-2.5 1.667V9.167a5.417 5.417 0 0 1 10.833 0v7.083Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M8.333 10a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667ZM11.667 10a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667Z"
      />
    </svg>
  ),
};
