import { FC } from 'react';
import { NavBar } from './NavBar';
import styles from './Header.module.scss';

export type HeaderProps = {
  color?: string | null;
};

export const Header: FC<HeaderProps> = ({ color = '#8445D4' }) => (
  <header className={styles.root}>
    <NavBar accentColor={color} />
  </header>
);
