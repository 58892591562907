import { FC } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { Link } from '@landr/maestro';

import { useGetGlobalSettings } from 'hooks';
import styles from './LoginButton.module.scss';

interface LoginButtonProps {
  size?: 'sm' | 'lg';
}

export const LoginButton: FC<LoginButtonProps> = ({ size = 'sm' }) => {
  const settings = useGetGlobalSettings();
  const { locale } = useRouter();

  const getLocale = (locale?: string) => {
    switch (locale) {
      case 'en':
        return 'en';
      case 'fr':
        return 'fr';
      case 'de':
        return 'de';
      case 'ja':
        return 'ja';
      case 'es':
        return 'es';
      case 'pt':
      case 'pt-br':
        return 'pt';
      case 'it':
        return 'it';
      default:
        return 'en';
    }
  };

  const loginLabel = settings?.loginLabel || 'Login or Register';
  const loginLink =
    settings?.loginLink ||
    `https://app.landr.com/?locale=${getLocale(locale)}&isSignup=true`;

  return (
    <Link
      href={loginLink}
      size="sm"
      className={cn(styles.login, {
        [styles[`size-${size}`]]: size,
      })}
      sx={{
        textDecoration: 'none',
        color: 'neutral.1000',
      }}
      rel="noreferrer"
    >
      {/* // TODO: Update login label https://mixgenius.atlassian.net/browse/CORE-11797 */}
      {size === 'sm' ? loginLabel : 'Join LANDR for free'}
    </Link>
  );
};
