import { FC } from 'react';
import Link from 'next/link';

import { useGetGlobalSettings } from 'hooks';
import LandrMini from '../../../public/icons/landr-mini.svg';
import { getHost } from '../../../utils/host';
import styles from './Logo.module.scss';

interface LogoProps {
  hasLogo?: boolean;
}

export const Logo: FC<LogoProps> = ({ hasLogo = true }) => {
  const settings = useGetGlobalSettings();

  return (
    <Link
      href={getHost() || `https://${settings?.siteLink}`}
      passHref
      className={styles.titleWrapper}
    >
      {hasLogo && <LandrMini className={styles.logo} />}
      <span className={styles.title}>LANDR Blog</span>
    </Link>
  );
};
