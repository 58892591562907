import Link from 'next/link';
import dayjs from 'dayjs';
import { WidgetBlock } from './WidgetBlock/WidgetBlock';
import { useLayoutContext } from 'components';
import LandrLarge from '../../public/icons/landr-large.svg';
import Spotify from '../../public/icons/spotify-logo.svg';
import LinkedIn from '../../public/icons/linkedin-logo.svg';
import Facebook from '../../public/icons/facebook-logo.svg';
import Twitter from '../../public/icons/twitter-logo.svg';
import Instagram from '../../public/icons/instagram-logo.svg';

import styles from './Footer.module.scss';
import { useGetGlobalSettings } from 'hooks';

export const Footer = () => {
  const { footerMenu } = useLayoutContext();
  const alsHeaderSettings = useGetGlobalSettings();

  const firstBlockLinks = footerMenu?.footer1?.nodes || [];
  const firstBlockTitle =
    footerMenu?.footer1?.nodes?.[0]?.menu?.node?.name || '';

  const secondBlockLinks = footerMenu?.footer2?.nodes || [];
  const secondBlockTitle =
    footerMenu?.footer2?.nodes?.[0]?.menu?.node?.name || '';

  const thirdBlockLinks = footerMenu?.footer3?.nodes || [];
  const thirdBlockTitle =
    footerMenu?.footer3?.nodes?.[0]?.menu?.node?.name || '';

  const currentYear = dayjs().format('YYYY');

  return (
    <footer className={styles.root}>
      <div className={styles.widgets}>
        <div>
          <p className={styles.title}>{alsHeaderSettings?.footerHeading}</p>
        </div>
        <WidgetBlock title={firstBlockTitle} menuLinks={firstBlockLinks} />
        <WidgetBlock title={secondBlockTitle} menuLinks={secondBlockLinks} />
        <WidgetBlock title={thirdBlockTitle} menuLinks={thirdBlockLinks} />
      </div>
      <div className={styles.bottomWidgets}>
        <div className={styles.extraInfo}>
          <div className={styles.usefulLinks}>
            <Link href="/" title="Landr" className={styles.logoWrapper}>
              <LandrLarge className={styles.logo} />
            </Link>

            <p
              className={styles.credentials}
            >{`LANDR © ${currentYear} ${alsHeaderSettings?.footerLinks?.copyrightNotice}`}</p>
          </div>

          <div className={styles.menu}>
            <a
              href={`${alsHeaderSettings?.footerLinks?.privacyPolicyLink}`}
              target="_blank"
              rel="noreferrer"
              className={styles.menuLink}
            >
              {alsHeaderSettings?.footerLinks?.privacyPolicyTitle}
            </a>
            <a
              href={`${alsHeaderSettings?.footerLinks?.termsLink}`}
              target="_blank"
              rel="noreferrer"
              className={styles.menuLink}
            >
              {alsHeaderSettings?.footerLinks?.termsTitle}
            </a>
          </div>
        </div>

        <div className={styles.social}>
          <a
            href="https://open.spotify.com/playlist/2UeCp7yf9FEkf9DATLwa4G"
            target="_blank"
            rel="noreferrer"
            title="Spotify"
            className={styles.socialLink}
          >
            <Spotify />
          </a>
          <a
            href="https://www.linkedin.com/company/landrmusic/"
            target="_blank"
            rel="noreferrer"
            title="LinkedIn"
            className={styles.socialLink}
          >
            <LinkedIn
              style={{
                width: '36px',
                height: '36px',
                marginRight: '-10px',
                marginLeft: '-10px',
              }}
            />
          </a>
          <a
            href="https://www.facebook.com/LANDRmusic/"
            target="_blank"
            rel="noreferrer"
            title="Facebook"
            className={styles.socialLink}
          >
            <Facebook />
          </a>
          <a
            href="https://twitter.com/LANDR_music"
            target="_blank"
            rel="noreferrer"
            title="Twitter"
            className={styles.socialLink}
          >
            <Twitter
              style={{
                width: '30px',
                height: '30px',
                marginRight: '-10px',
                marginLeft: '-6px',
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/landrmusic/"
            target="_blank"
            rel="noreferrer"
            title="Instagram"
            className={styles.socialLink}
          >
            <Instagram />
          </a>
        </div>
      </div>
    </footer>
  );
};
