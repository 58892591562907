import { VideoMetaTag } from 'types';

interface VideoSeoMetaTagsProps {
  metaTags?: VideoMetaTag[];
}

export const VideoSeoMetaTags = ({ metaTags }: VideoSeoMetaTagsProps) => {
  if (!metaTags || !metaTags.length) {
    return null;
  }

  // See https://developers.google.com/search/docs/appearance/structured-data/video#video-object for more details
  const videoElement = (metaTag: VideoMetaTag) => {
    const optionalProps: any = {};
    if (metaTag.viewCount) {
      optionalProps.interactionStatistic = {
        '@type': 'InteractionCounter',
        interactionType: { '@type': 'WatchAction' },
        userInteractionCount: metaTag.viewCount,
      };
    }
    return {
      '@type': 'VideoObject',
      name: metaTag.name,
      description: metaTag.description,
      thumbnailUrl: metaTag.thumbnailUrl,
      duration: metaTag.duration,
      contentUrl: metaTag.contentUrl,
      embedUrl: metaTag.embedUrl,
      uploadDate: metaTag.uploadDate,
      ...optionalProps,
    };
  };

  let schemaData: any = {
    '@context': 'https://schema.org',
  };
  if (metaTags.length === 1) {
    schemaData = { ...schemaData, ...videoElement(metaTags[0]) };
  } else {
    const itemListElement = metaTags.map((metaTag, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        item: videoElement(metaTag),
      };
    });

    const itemList = {
      '@type': 'ItemList',
      itemListElement: itemListElement,
    };
    schemaData = { ...schemaData, ...itemList };
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
    />
  );
};
